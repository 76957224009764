/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { AuthCheckProvider } from "../hooks/useAuthCheck"
import { useTranslation } from "../utils"
import { useAnalytics } from "../hooks/useTracking"

const Layout = ({ children }) => {
  return (
    <div>
      <div>
        <AuthCheckProvider>
          <main>{children}</main>
        </AuthCheckProvider>
      </div>
      <Alerts />
    </div>
  )
}

const Alerts = () => {
  const [isCookieAgree, setIsCookieAgree] = useState(true)

  useEffect(() => {
    if (!localStorage.getItem("isCookieAgree")) {
      setIsCookieAgree(false)
    }
  }, [])

  return (
    <div className="wt-alerts-block">
      {!isCookieAgree && <Cookies setIsCookieAgree={setIsCookieAgree} />}
    </div>
  )
}

const Cookies = ({ setIsCookieAgree }) => {
  const { t } = useTranslation()
  const analytics = useAnalytics()

  const handleClose = () => {
    analytics.clickAllowCookies()
    localStorage.setItem("isCookieAgree", true)
    setIsCookieAgree(true)
  }

  return (
    <div className="wt-alert__cookie">
      <div className="wt-alert__cookie-content">
        <p>
          {t("We use cookies to give you the best experience on our website")}
        </p>
        <p>
          {t(
            "By continuing to browse the site, you are agreeing to our use of cookies"
          )}{" "}
          &mdash;{" "}
          <Link
            onClick={analytics.clickGoToPP}
            to="/privacy"
            target="_blank"
            className="privacy-link"
          >
            {t("Privacy Policy")}
          </Link>
        </p>
      </div>
      <button
        className="wt-button wt-button--primary wt-button--alert"
        type="button"
        onClick={handleClose}
      >
        OK
      </button>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
