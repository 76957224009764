import React from "react"
import { Link } from "gatsby"
import SubmarineImage from "../../images/submarine.png"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "./styles.css"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="not-found-page">
      <img src={SubmarineImage} alt={"Not found image"} />
      <div className="not-found-page__title">404 Page Not Found</div>
      <div className="not-found-page__text">
        Oopps. The page you were looking for doesn't exist.
      </div>
      <div className="not-found-page__description">
        Oopps. The page you were looking for doesn't exist.
        <br /> You may have mistyped the address or the page may have moved. Try
        to go home and do it again
      </div>
      <Link className="wt-button wt-button--primary wt-button--small" to="/">
        go home
      </Link>
    </div>
  </Layout>
)

export default NotFoundPage
