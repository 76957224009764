import React, { useContext, useEffect } from "react"
import { CONFIG } from "../config"

export function createAuthCheckContext(defaultValue) {
  const defaultUpdate = () => defaultValue
  const ctx = React.createContext({
    state: defaultValue,
    update: defaultUpdate,
  })
  function Provider(props) {
    const [state, update] = React.useState(defaultValue)

    useEffect(() => {
      const checkToken = async () => {
        const tokenData = JSON.parse(localStorage.getItem("tokenData"))
        if (tokenData) {
          try {
            const refreshSession = await refreshToken(
              tokenData.value,
              tokenData.refreshToken
            )
            if (refreshSession) {
              update({
                link: "/cabinet/sign-in",
                linkText: "Dashboard",
                authed: true,
              })
            }
          } catch (e) {}
        }
      }
      checkToken()
    }, [])

    return <ctx.Provider value={{ state, update }} {...props} />
  }
  return [ctx, Provider]
}

const [AuthCheckContext, AuthCheckProvider] = createAuthCheckContext({
  linkText: "Sign in",
  link: "/cabinet/sign-in",
  authed: false,
})

export { AuthCheckContext, AuthCheckProvider }

export const useAuthCheck = () => {
  const context = useContext(AuthCheckContext)
  if (!context) {
    throw new Error(`useAuthCheck must be used within a AuthCheckContext`)
  }

  const { link, linkText, authed } = context.state

  return {
    link,
    linkText,
    authed,
  }
}

const refreshToken = async (token, refreshToken) => {
  try {
    const fetchResult = await fetch(CONFIG?.API_URL + "/graphql", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        query: `
        mutation {
          guest {
            users {
              refreshSession(refreshToken: "${refreshToken}" ) {
                value
                refreshToken
                expiresIn
              }
            }
          }
        }
      `,
      }),
    })

    const refreshResponse = await fetchResult.json()

    const refreshSession = refreshResponse.data.guest.users.refreshSession
    saveToken(refreshSession)

    return refreshSession
  } catch (e) {
    localStorage.removeItem("tokenData")
    return null
  }
}

const saveToken = token => {
  localStorage.setItem(
    "tokenData",
    JSON.stringify({
      ...token,
      expiresIn: new Date().getTime() + token.expiresIn * 1000,
    })
  )
}
